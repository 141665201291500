<template>
    <div class="withdrawal">
        <div class="info">
            <div class="hearder">
                <span class="left">提现到</span>
                <div class="right">
                    <img src="@/assets/activeImgs/wx_icon.png" alt="">
                    <span>微信</span>
                </div>
            </div>
            <div class="money">
                <div class="field">
                    <img src="@/assets/activeImgs/money_icon.png" alt="">
                    <van-field type="number" v-model="money" placeholder="请输入可提现金额" />
                </div>
                <div></div>
            </div>
            <div class="moneyNum">
                <span>手续费：￥13.00</span>
                <span class="allMoney">全部提现</span>
            </div>
            <div class="btn">提现</div>
        </div>
        <div class="detail">
            <div class="title">
                <span class="text">提现明细</span>
                <div class="options" @click="optionShow = !optionShow">
                    <span>{{ time }}</span>
                    <van-icon name="arrow-down" :class=" optionShow ? 'up' : 'down' " />
                </div>
            </div>
            <ul class="list">
                <li v-for="( item,index ) in list" :key="index"
                    :style=" index == list.length - 1 ? 'border:none;' : '' ">
                    <div class="status_box">
                        <span class="status">{{ item.status }}</span>
                        <span class="no">{{ item.no }}</span>
                    </div>
                    <div class="money_box">
                        <span class="time">{{ item.time }}</span>
                        <span class="money">{{ item.money }}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            money: '',
            time: '2021-08',
            option: [
                { text: '2021-08', value: '2021-08' },
                { text: '2021-09', value: '2021-09' },
                { text: '2021-10', value: '2021-10' },
            ],
            optionShow: false,
            list: [
                {
                    status: '微信提现成功',
                    time: '2021-08-23  10:23:30',
                    no: '流水号:14251245694523',
                    money: '- 256.30'
                },
                {
                    status: '微信提现成功',
                    time: '2021-08-23  10:23:30',
                    no: '流水号:14251245694523',
                    money: '- 256.30'
                },
                {
                    status: '微信提现成功',
                    time: '2021-08-23  10:23:30',
                    no: '流水号:14251245694523',
                    money: '- 256.30'
                },
            ]
        }
    }
}
</script>

<style lang='scss' scoped>
    .withdrawal{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #F8F8F8;
        overflow-y: scroll;
        padding: 15px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        .info{
            width: 350px;
            height: 250px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            opacity: 1;
            border-radius: 8px;
            .hearder{
                height: 48px;
                display: flex;
                padding: 0 15px;
                box-sizing: border-box;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid rgba(109, 114, 120, 0.3);
                font-size: 16px;
                .left{
                    color: #8A8A8A;         
                }
                .right{
                    color: #141414;
                    display: flex;
                    align-items: center;
                    img{
                        width: 16px;
                        height: 16px;
                        margin-right: 6px;
                    }
                }
            }
            .money{
                height: 68px;
                display: flex;
                align-items: center;
                padding: 0 15px;
                box-sizing: border-box;
                border-bottom: 1px solid rgba(109, 114, 120, 0.3);
                .field{
                    display: flex;
                    align-items: center;
                    img{
                        width: 23px;
                        height: 30px;
                    }
                }
            }
            .moneyNum{
                padding: 15px 15px 30px;
                display: flex;
                justify-content: space-between;
                span{
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    line-height: 20px;
                    color: #4C4B5B;
                    letter-spacing: 0.5px;
                }
                .allMoney{
                    color: #357EFE;
                }
            }
            .btn{
                width: 300px;
                height: 46px;
                background: linear-gradient(180deg, #5C9AFE 0%, #357EFE 100%);
                opacity: 1;
                border-radius: 4px;
                font-size: 16px;
                font-family: PingFangSC-Medium;
                color: #FFFFFF;
                text-align: center;
                line-height: 46px;
                margin: 0 auto;
            }
        }
        .detail{
            width: 350px;
            min-height: 315px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            margin-top: 15px;
            .title{
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 15px;
                box-sizing: border-box;
                border: 1px solid #E5E5E5;
                .text{
                    line-height: 22px;
                    color: #141414;     
                }
                .options{
                    display: flex;
                    align-items: center;
                    color: #4C4B5B;
                    span{
                        margin-right: 8px;
                        font-size: 14px;
                    }
                    .up{
                        transition: transform 0.3s;
                        transform: rotateZ(180deg);
                    }
                    .down{
                        transition: transform 0.3s;
                        transform: rotateZ(0deg);
                    }
                }
            }
            .list{
                padding: 0 15px;
                box-sizing: border-box;
                li{
                    height: 84px;
                    border-bottom: 1px solid #E5E5E5;
                    padding: 15px 0;
                    box-sizing: border-box;
                    .status_box{
                        .status{
                            height: 22px;
                            font-size: 16px;
                            font-family: PingFang SC;
                            font-weight: bold;
                            line-height: 19px;
                            color: #141414;
                            margin-right: 15px;
                        }
                        .no{
                            height: 20px;
                            font-size: 14px;
                            font-family: PingFang SC;
                            font-weight: 400;
                            line-height: 19px;
                            color: #1F2129;
                        }
                    }
                    .money_box{
                        margin-top: 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .time{
                            font-size: 12px;
                            font-family: PingFang SC;
                            font-weight: 400;
                            line-height: 19px;
                            color: #ABAAAA;
                        }
                        .money{
                            font-size: 18px;
                            font-family: PingFang SC;
                            font-weight: bold;
                            line-height: 18px;
                            color: #FF4B13;
                        }
                    }
                }
            }
        }
    }
</style>